import React from "react";
import styled from "styled-components";

import SEO from "../components/SEO";
import Container from "../components/container";
import ServiceLinks from "../components/serviceLinks";

const StyledServiceLinks = styled.div`
    ul {
        list-style-type: none;
        padding-left: 20px;
    }
    li {
        padding: 5px 0;
    }
    li a.serviceLink {
        position: relative;
        text-decoration: none;
        font-size: 18px;
        font-weight: 500;
        &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            transition: 100ms ease-out 50ms;
            transform-origin: 0 24px;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20%;
            background: #e1e5ea;
        }
        &:hover {
            &:before {
                transition: 100ms ease-out;
                background: #8995a9;
            }
        }
    }
`;

const diensten = ({ location }) => {
    return (
        <>
            <SEO title="Onze diensten" description="Kies uit onze waaier aan voordelige behandelingen en laat je verwennen." path={location.pathname} />
            <Container>
                <StyledServiceLinks>
                    <ServiceLinks />
                </StyledServiceLinks>
            </Container>
        </>
    );
};

export default diensten;
